<template>
  <div id="Manage">
    <!-- 商城模块 物流/快递公司管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">增加</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchKeyword(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @changeSwitch="changeSwitch"
                     @getPage="getPage"
                     style="height:560px">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click=clickDeleteData(scope.row)>删除</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getLogisticsList, metLogisticsList, putStatusLogisticsData } from 'api/shopMoudle.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {

      searchData: '',//存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增物流/快递公司',
        editName: '编辑物流/快递公司',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        id: '',
        no: '',
        name: '',
        link_url: '',
        image: '',
        sort: '',
        status: ''
      },
      openFormLabel: [
        {
          model: 'no',
          label: '快递公司编号',
        },
        {
          model: 'name',
          label: '快递公司名称',
        },
        {
          model: 'link_url',
          label: '快递公司网站'
        },
        {
          model: 'image',
          label: '公司LOGO',
          type: 'upload',
          imageUrl: '',
          maxSize: 2048,
          imgHeight: '1280',
          imgWidth: '808',
          clues: '只能上传jpg/png文件，且格式为80x80'
        },
        {
          model: 'sort',
          label: '排序',
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          width: '300px',
          options: [
            // value:'值',label:'标签'
            { value: 1, label: '启用' },
            { value: 0, label: '禁用' },
          ],
        }
      ],

      // form表单 搜索数据
      searchForm: {
        id: '',
        name: '',
        status: '',
      },
      searchformLabel: [
        {
          model: 'id',
          label: '快递公司编号',
          width: '300px'
        },
        {
          model: 'name',
          label: '快递公司名',
          width: '300px'
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          width: '300px',
          options: [
            // value:'值',label:'标签'
            { value: 1, label: '启用' },
            { value: 0, label: '禁用' },
          ],
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '公司编号',
        },
        {
          prop: 'name',
          label: '公司名称'
        },
        {
          prop: 'image',
          label: 'LOGO',
          type: 'logo'
        },
        {
          prop: 'link_url',
          label: '链接地址',
        },
        {
          prop: 'create_time',
          label: '更新时间'
        },
        {
          prop: 'sort',
          label: '序号'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'state',
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false,
      },

      // 物流/快递公司-全部-数据
      brandAllDataObj: {},
      // 物流/快递公司-格式处理-列表数据
      barndFormatDealListData: [],
      // category_id最大值
      MaxId: ''
    }
  },
  mounted () {
    this.getLogisticsList()
  },
  watch: {
    brandAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.brandAllDataObj.list,
        {
          // status: 'state',
        },
        0
      )
    }
  },

  methods: {
    //获得下列分类页的页数
    getPage () {
      // this.getScenicList()
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getLogisticsList(this.searchData)
      } else {
        this.getLogisticsList()
      }
    },
    //   获取--物流/快递公司列表数据
    getLogisticsList (data) {
      if (data) {
        getLogisticsList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.brandAllDataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getLogisticsList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.brandAllDataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }



    },

    // 更新数据  -- 添加-编辑 物流/快递公司
    metLogisticsList (met, data) {
      metLogisticsList(met, data).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          this.getLogisticsList()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    //改变状态
    putStatusLogisticsData (met, data) {
      putStatusLogisticsData(met, data).then(res => {
        if (res.data.code == 200) {
          console.log(res)
          this.$message({
            message: res.data.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 category_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxCatgroyId(this.tableData, 'id')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      // id: ""
      // name: ""
      // Ename: ""
      // status: true
      // ---------数据格式--------------------
      // category_id:1
      // code:huawei
      // name:华为
      // en_name:huawei

      //  no: '',
      //   name: '',
      //   link_url: '',
      //   image: '',
      //   sort: '',
      //   status: ''
      if (type == 'add') {
        console.log(DATA)
        const DATA = {
          no: data.no,
          name: data.name,
          link_url: data.link_url,
          status: data.status,
          sort: data.sort,
          image: data.image,
        }
        console.log(DATA)
        this.metLogisticsList('POST', DATA)
      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          no: data.no,
          name: data.name,
          link_url: data.link_url,
          status: data.status,
          sort: data.sort,
          image: data.image,
        }
        this.metLogisticsList('PUT', DATA)
      }
      this.dialogFonfig.isShowDialogVisible = false
      this.getLogisticsList()
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // 编辑操作
    clickEditData (row) {
      // id	是	int	品牌ID
      // category_id		int	品牌分类ID
      // code		string	助记码
      // name		string	品牌名
      // en_name		string	英文名
      // logo		url	LOGO
      // sort		int	排序
      // status		int	状态 1启用 0禁用
      console.log(row)
      // const state = row.state == 1
      this.openForm = {
        id: row.id,
        no: row.id,
        name: row.name,
        link_url: row.link_url,
        status: row.status,
        sort: row.sort,
        image: row.image,
      }
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 删除操作
    clickDeleteData (row) {
      this.$confirm('确定移除这条记录吗？', '提示', { type: "error" }).then(() => {
        const DATA = {
          id: row.id
        }
        this.metLogisticsList('DELETE', DATA)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })

    },
    // 表格开关操作
    changeSwitch (row) {
      // id		品牌ID
      // status	int	状态 1启用 0禁用
      console.log(row)
      const DATA = {
        id: row.id,
        status: row.status
      }

      this.putStatusLogisticsData('PUT', DATA)
      console.log(row)
    },
    //搜索数据
    searchKeyword (searchForm) {
      console.log(searchForm)
      const data = {
        id: searchForm.id,
        name: searchForm.name,
        status: searchForm.status,
      }
      this.searchData = data
      this.getLogisticsList(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.id = '',
        searchForm.brandName = '',
        searchForm.name = '',
        searchForm.status = '',
        this.searchData = ''
      this.getLogisticsList()
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  /deep/ .el-dialog {
    width: 35%;
  }
  .filtrateInput {
    width: 70%;
    background: #f8f7f7;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-table__body {
    // height: 360px;
  }
}
</style>
